#films {
    margin-top: 70px;
    width: 100vw;
}

#films > #grid {
    display: grid;
    gap: 15px;
    grid-template-columns: 1fr 1fr 1fr;
    margin: 20px 5%;
}

#films > #grid > article {
    background-color: #000;
    display: grid;
    place-items: center;
    position: relative;
    cursor: pointer;
}

#films > #grid > article > h2 {
    color: white;
    font-family: 'Jost', sans-serif;
    font-size: 25px;
    display: inline;
    opacity: 0;
    transition-duration: 300ms;
    position: absolute;
    z-index: 500;
}

#films > #grid > article:hover > h2 {
    opacity: 1;
}

#films > #grid > article > img {
    width: 100%;
    aspect-ratio: 16/9;
    object-fit: cover;
    transition-duration: 400ms;
}

#films > #grid > article:hover > img {
    opacity: 0.7;
}

@media only screen and (max-width: 1000px) {
    #films > #grid {
        grid-template-columns: 1fr 1fr;
    } 
}

@media only screen and (max-width: 700px) {
    #films > #grid > article > h2 {
        opacity: 1;
    }

    #films > #grid > article > img {
        opacity: 0.7;
    }

    #films > #grid {
        grid-template-columns: 1fr;
    } 
}