#home {
    margin-top: 70px;
    width: 100vw;
    height: calc(100vh - 90px - 56.5px);
    display: grid;
    place-items: center;
    margin-bottom: 20px;
}

#home > #about {
    font-family: 'Sen', sans-serif;
    height: 70vh;
    width: 80%;
    display: grid;
    gap: 25px;
    grid-template-columns: 1fr 1fr;
}

#home > #about > #image {
    height: 70vh;
    text-align: center;
}

#home > #about > #image > img {
    height: 100%;
    aspect-ratio: 2/3;
}

#home > #about > #text {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    gap: 15px;
    flex-direction: column;
}

#home > #about > #text > h1 {
    font-weight: 700;
    font-size: 38px;
}

#home > #about > #text >p {
    font-family: 'Jost';
    font-size: 17px;
}

@media only screen and (max-width: 800px) {
    #home {
        height: auto;
    }

    #home > #about {
        width: 85%;
        height: auto;
        grid-template-columns: 1fr;
    }

    #home > #about > #image {
        height: auto;
        text-align: center;
    }

    #home > #about > #image > img {
        width: 100%;
        height: 480px;
        aspect-ratio: 2/3;
        object-fit: cover;
    }
}