@import url('https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@48,400,0,0');
@import url('https://fonts.googleapis.com/css2?family=Jost:wght@400;500&family=Sen:wght@400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Spectral:ital,wght@1,300;1,400&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

#navigation {
  position: fixed;
  top: 0;
  width: 100vw;
  background-color: #FFF;
  z-index: 1000;
  display: flex;
  justify-content: space-between;
}

#navigation > header {
  margin: 20px 5%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#navigation > header > h1 {
  font-family: 'Sen', sans-serif;
  font-size: 25px;
  cursor: pointer;
  transition-duration: 200ms;
}

#navigation > header > h1:hover {
  opacity: 0.5;
}

#navigation > #mobile-header {
  display: none;
}

#navigation > nav {
  margin: 20px 5%;
  display: flex;
  gap: 20px;
  align-items: center;
  font-family: 'Jost', sans-serif;
}

#navigation > nav > a {
  font-size: 17px;
  transition-duration: 200ms;
  cursor: pointer;
}

#navigation > nav > a:hover {
  opacity: 0.5;
}

#navigation > nav > ul {
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: right;
}

#navigation > nav > ul > a {
  display: flex;
  align-items: center;
  transition-duration: 200ms;
}

#navigation > nav > ul > a:hover {
  opacity: 0.5;
}

.social-icons {
  width: 20px;
}

@media only screen and (max-width: 800px) {
  #navigation {
    display: block;
  }

  #navigation > #mobile-header {
    display: flex;
  }

  #navigation > #desktop-header {
    display: none;
  }

  #navigation > nav {
    display: none;
    height: calc(100vh - 90px);
    flex-direction: column;
    justify-content: center;
  }

  #navigation > .navbar-active {
    display: flex;
  }

  #navigation > nav > a {
    font-weight: 700;
    font-size: 2.5em;
  }

  .social-icons {
    width: 30px;
  }
}

/* FOOTER */

footer {
  margin: 20px 5%;
  font-family: 'Spectral', serif;
  font-style: italic;
  margin-top: auto;
}

footer > hr {
  margin-bottom: 10px;
}

footer > p {
  width: 100%;
  text-align: center;
}