@import url(https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@48,400,0,0);
@import url(https://fonts.googleapis.com/css2?family=Jost:wght@400;500&family=Sen:wght@400;500;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Spectral:ital,wght@1,300;1,400&display=swap);
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

#navigation {
  position: fixed;
  top: 0;
  width: 100vw;
  background-color: #FFF;
  z-index: 1000;
  display: flex;
  justify-content: space-between;
}

#navigation > header {
  margin: 20px 5%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#navigation > header > h1 {
  font-family: 'Sen', sans-serif;
  font-size: 25px;
  cursor: pointer;
  transition-duration: 200ms;
}

#navigation > header > h1:hover {
  opacity: 0.5;
}

#navigation > #mobile-header {
  display: none;
}

#navigation > nav {
  margin: 20px 5%;
  display: flex;
  grid-gap: 20px;
  gap: 20px;
  align-items: center;
  font-family: 'Jost', sans-serif;
}

#navigation > nav > a {
  font-size: 17px;
  transition-duration: 200ms;
  cursor: pointer;
}

#navigation > nav > a:hover {
  opacity: 0.5;
}

#navigation > nav > ul {
  display: flex;
  grid-gap: 20px;
  gap: 20px;
  align-items: center;
  justify-content: right;
}

#navigation > nav > ul > a {
  display: flex;
  align-items: center;
  transition-duration: 200ms;
}

#navigation > nav > ul > a:hover {
  opacity: 0.5;
}

.social-icons {
  width: 20px;
}

@media only screen and (max-width: 800px) {
  #navigation {
    display: block;
  }

  #navigation > #mobile-header {
    display: flex;
  }

  #navigation > #desktop-header {
    display: none;
  }

  #navigation > nav {
    display: none;
    height: calc(100vh - 90px);
    flex-direction: column;
    justify-content: center;
  }

  #navigation > .navbar-active {
    display: flex;
  }

  #navigation > nav > a {
    font-weight: 700;
    font-size: 2.5em;
  }

  .social-icons {
    width: 30px;
  }
}

/* FOOTER */

footer {
  margin: 20px 5%;
  font-family: 'Spectral', serif;
  font-style: italic;
  margin-top: auto;
}

footer > hr {
  margin-bottom: 10px;
}

footer > p {
  width: 100%;
  text-align: center;
}
#films {
    margin-top: 70px;
    width: 100vw;
}

#films > #grid {
    display: grid;
    grid-gap: 15px;
    gap: 15px;
    grid-template-columns: 1fr 1fr 1fr;
    margin: 20px 5%;
}

#films > #grid > article {
    background-color: #000;
    display: grid;
    place-items: center;
    position: relative;
    cursor: pointer;
}

#films > #grid > article > h2 {
    color: white;
    font-family: 'Jost', sans-serif;
    font-size: 25px;
    display: inline;
    opacity: 0;
    transition-duration: 300ms;
    position: absolute;
    z-index: 500;
}

#films > #grid > article:hover > h2 {
    opacity: 1;
}

#films > #grid > article > img {
    width: 100%;
    aspect-ratio: 16/9;
    object-fit: cover;
    transition-duration: 400ms;
}

#films > #grid > article:hover > img {
    opacity: 0.7;
}

@media only screen and (max-width: 1000px) {
    #films > #grid {
        grid-template-columns: 1fr 1fr;
    } 
}

@media only screen and (max-width: 700px) {
    #films > #grid > article > h2 {
        opacity: 1;
    }

    #films > #grid > article > img {
        opacity: 0.7;
    }

    #films > #grid {
        grid-template-columns: 1fr;
    } 
}
#film {
    margin-top: 70px;
    width: 100vw;
    height: calc(100vh - 90px);
}

#film > #grid {
    display: grid;
    height: 100%;
    place-items: center;
    margin: 20px 5%;
}

#film > #grid > iframe {
    width: 80%;
    aspect-ratio: 16/9;
}

@media only screen and (max-width: 1000px) {
    #film > #grid > iframe {
        width: 85%;
    }
}

@media only screen and (max-width: 700px) {
    #film > #grid > iframe {
        aspect-ratio: 9/16;
        width: 100%;
    }
}


#photo-albums {
    margin-top: 70px;
    width: 100vw;
}

#photo-albums > #grid {
    display: grid;
    grid-gap: 15px;
    gap: 15px;
    grid-template-columns: 1fr 1fr 1fr;
    margin: 20px 5%;
}

#photo-albums > #grid > article {
    background-color: black;
    position: relative;
    display: grid;
    place-items: center;
    cursor: pointer;
}

#photo-albums > #grid > article > h2 {
    color: white;
    font-family: 'Jost', sans-serif;
    font-size: 25px;
    display: inline;
    opacity: 0;
    transition-duration: 300ms;
    position: absolute;
    z-index: 500;
}

#photo-albums > #grid > article:hover > h2 {
    opacity: 1;
}

#photo-albums > #grid > article > img {
    width: 100%;
    aspect-ratio: 3/2;
    object-fit: cover;
    transition-duration: 400ms;
}

#photo-albums > #grid > article:hover > img {
    opacity: 0.7;
}


@media only screen and (max-width: 1000px) {
    #photo-albums > #grid {
        grid-template-columns: 1fr 1fr;
    } 
}

@media only screen and (max-width: 700px) {
     #photo-albums > #grid > article > h2 {
        opacity: 1;
    }

    #photo-albums > #grid > article > img {
        opacity: 0.7;
    }

    #photo-albums > #grid {
        grid-template-columns: 1fr;
    } 
}
#photos {
    margin-top: 70px;
    width: 100vw;
}

#photos > #column-container {
    display: flex;
    grid-gap: 15px;
    gap: 15px;
    margin: 20px 5%;
}

#photos > #column-container > #column {
    display: flex;
    flex-direction: column;
    grid-gap: 15px;
    gap: 15px;
}

#photos > #column-container > #column > img {
   width: 100%;
}


#gear {
    margin-top: 70px;
    width: 100vw;
    font-family: 'Sen', sans-serif;
}

#gear > p {
    font-size: 15px;
    margin: 20px 5%;
    padding: 20px;
    color: #FFF;
    background-color: #000;;
}

#gear > #grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin: 0 5% 20px;
    padding-bottom: 20px;
    background-color: #000;
}

#gear > #grid > article {
    background-color: #000;
    cursor: pointer;
    transition-duration: 300ms;
}


#gear > #grid > article > a {
    display: block;
    width: 100%;
    text-decoration: none;
    color: #FFF;
}


#gear > #grid > article:hover {
    opacity: 0.5;
}

#gear > #grid > article > a > p {
    width: 100%;
    text-align: center;
    padding-top: 20px;
}

#gear > #grid > article > a > img {
    width: 100%;
}

@media only screen and (max-width: 1000px) {
    #gear > #grid {
        grid-template-columns: 1fr 1fr;
    } 
}

@media only screen and (max-width: 700px) {
    #gear > #grid {
        grid-template-columns: 1fr;
    } 
}
#home {
    margin-top: 70px;
    width: 100vw;
    height: calc(100vh - 90px - 56.5px);
    display: grid;
    place-items: center;
    margin-bottom: 20px;
}

#home > #about {
    font-family: 'Sen', sans-serif;
    height: 70vh;
    width: 80%;
    display: grid;
    grid-gap: 25px;
    gap: 25px;
    grid-template-columns: 1fr 1fr;
}

#home > #about > #image {
    height: 70vh;
    text-align: center;
}

#home > #about > #image > img {
    height: 100%;
    aspect-ratio: 2/3;
}

#home > #about > #text {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    grid-gap: 15px;
    gap: 15px;
    flex-direction: column;
}

#home > #about > #text > h1 {
    font-weight: 700;
    font-size: 38px;
}

#home > #about > #text >p {
    font-family: 'Jost';
    font-size: 17px;
}

@media only screen and (max-width: 800px) {
    #home {
        height: auto;
    }

    #home > #about {
        width: 85%;
        height: auto;
        grid-template-columns: 1fr;
    }

    #home > #about > #image {
        height: auto;
        text-align: center;
    }

    #home > #about > #image > img {
        width: 100%;
        height: 480px;
        aspect-ratio: 2/3;
        object-fit: cover;
    }
}
